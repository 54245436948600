import { useState, useEffect } from 'react';

const useFilters = (filterConfig, clearState) => {
    const [filters, setFilters] = useState({});
    const [rangeValues, setRangeValues] = useState({});

    useEffect(() => {
        const initFilters = async () => {
                setRangeValues({checkSize: [filterConfig['checkSize'].min, filterConfig['checkSize'].max]});
                // Initialize filters based on FILTER_CONFIG and fetched ranges
                const initialFilters = Object.keys(filterConfig).reduce((acc, key) => {
                    acc[key] = filterConfig[key].type === 'range'
                        ? [filterConfig[key].min, filterConfig[key].max]
                        : filterConfig[key].type === 'multi-select' ? [] : '';
                    return acc;
                }, {});
                setFilters(initialFilters);
        };
        initFilters();
    }, []);

    const updateFilter = (name, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };

    const clearAllFilters = () => {
        const resetFilters = Object.keys(filterConfig).reduce((acc, key) => {
            acc[key] = filterConfig[key].type === 'range'
                ? [filterConfig[key].min, filterConfig[key].max]
                : filterConfig[key].type === 'multi-select' ? [] : '';
            return acc;
        }, {});
        setFilters(resetFilters);
        clearState(resetFilters);
    };

    return { filters, setFilters,  rangeValues, updateFilter, clearAllFilters };
};

export default useFilters;
