import React, { useState, useEffect } from 'react';
import {
    TextField,
    MenuItem,
    Select,
    Checkbox,
    Slider,
    FormControlLabel,
    CircularProgress, Typography
} from '@mui/material';
import { debounce } from 'lodash';
import api from '../../api';
import mapImage from '../../assets/images/map.png'

const MultiSelectFilter = ({ label, filterType, selectedOptions, appliedOptions, onChange , onlySearch }) => {
    const [options, setOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    // const {showSpinner, startLoading, stopLoading} = useLoading(0);
    const [tempSearch, setTempSearch] = useState(onlySearch);
    const [isLoading, setIsLoading] = useState(!onlySearch);

    const fetchOptions = async (query) => {
        if (!onlySearch || (onlySearch && query.trim().length > 0)) {
            try {
                const response = await api.get('/data/filter_suggestions', {
                    params: {filter_type: filterType, query},
                });
                setOptions(response.data);
            } catch (error) {
                console.error("Error fetching filter options:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const debouncedFetchOptions = debounce(fetchOptions, 300);

    useEffect(() => {
        debouncedFetchOptions(searchQuery);
        return () => debouncedFetchOptions.cancel();
    }, [searchQuery]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        if (onlySearch) {
            if (e.target.value.trim().length > 0) {
                setTempSearch(false)
            } else {
                setTempSearch(true)
            }
        }
    };

    const handleCheckboxChange = (option) => {
        const updatedOptions = selectedOptions.includes(option)
            ? selectedOptions.filter((item) => item !== option)
            : [...selectedOptions, option];
        onChange(updatedOptions);
    };

    // Sort options based on appliedOptions (only change after Apply is clicked)
    const sortedOptions = [...options].sort((a, b) => {
        const isSelectedA = appliedOptions.includes(a); // Use final applied state for sorting
        const isSelectedB = appliedOptions.includes(b);
        return isSelectedB - isSelectedA;
    });

    const optionsToShow =  tempSearch && appliedOptions.length > 0 ? appliedOptions : sortedOptions;

    return (
        <div>
            {(isLoading && !tempSearch) ? (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
                    <CircularProgress size={24} />
                </div>
            ) : (
                <div>
                    <TextField
                        label={`Search ${label}`}
                        variant="outlined"
                        size="small"
                        onChange={handleSearchChange}
                        value={searchQuery}
                        style={{ marginBottom: 8 }}
                    />
                    { (tempSearch && appliedOptions.length === 0) ? (
                        <div style={{ display: "flex", justifyContent: 'center', padding: '6px'}}>
                            <img src={mapImage} alt='mapImage' style={{ borderRadius: '72px' }} />
                            <div style={{alignItems : "center", display: 'flex'}}>
                            <Typography variant='body1' fontFamily='inter' sx={{pl:'14px'}}> Search to see results
                            </Typography>
                            </div>
                        </div>
                    ) :  (<div
                        style={{
                            maxHeight: '200px',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {optionsToShow.map((option) => (
                            <FormControlLabel
                                key={option}
                                control={
                                    <Checkbox
                                        style={{
                                            color: "#7F56D9",
                                        }}
                                        checked={selectedOptions.includes(option)} // Use temporary state here
                                        onChange={() => handleCheckboxChange(option)}
                                    />
                                }
                                label={option}
                                style={{display: 'block'}} // Ensure each option is in a new row
                            />
                        ))}
                    </div>
                    )}
                </div>
            )}
        </div>
    );
};

const SingleSelectFilter = ({label, filterType, onChange}) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await api.get('/data/filter_suggestions', {
                    params: {filter_type: filterType}
                });
                setOptions(response.data);
            } catch (error) {
                console.error("Error fetching filter options:", error);
            }
        };
        fetchOptions();
    }, []);

    const handleSelectChange = (event) => {
        onChange(filterType, event.target.value);
    };

    return (
        <Select
            label={`Select ${label}`}
            onChange={handleSelectChange}
            style={{width: 200}}
            displayEmpty
        >
            <MenuItem value="">
                <em>None</em>
            </MenuItem>
            {options.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </Select>
    );
};


const discreteRanges = [
    { label: 'Under 100K', value: 0.1 }, // 100K
    { label: '250K', value: 0.25 },
    { label: '500K', value: 0.5 },
    { label: '1M', value: 1 }, // 1M
    { label: '5M', value: 5 }, // 5M
    { label: '10M', value: 1 }, // 10M
    { label: '20M', value: 20 }, // 20M
    { label: '50M', value: 50 }, // 50M
    { label: '100M', value: 100 }, // 100M
    { label: '200M+', value: 200 }, // 200M+
];


const RangeFilter = ({ label, selectedRange, onChange }) => {
    const [range, setRange] = useState([0, discreteRanges.length - 1]); // Default to full range
    useEffect(() => {
        const startIndex = discreteRanges.findIndex(range => range.value >= selectedRange[0]);
        const endIndex = discreteRanges.findIndex(range => range.value >= selectedRange[1]);
        setRange([startIndex !== -1 ? startIndex : 0, endIndex !== -1 ? endIndex : discreteRanges.length - 1]);
    }, [selectedRange]);

    const handleRangeChange = (event, newRange) => {
        setRange(newRange);
        const transformedRange = [discreteRanges[newRange[0]].value, discreteRanges[newRange[1]].value];
        onChange(transformedRange);
    };

    return (
        <div style={{width: 200, margin: '0 8px'}}>
            <Typography variant="subtitle1">{label}</Typography>
            <Slider
                value={range}
                onChange={handleRangeChange}
                valueLabelDisplay="auto"
                marks={discreteRanges.map((range, index) => ({ value: index }))}
                step={null} // Disable intermediate steps
                min={0}
                max={discreteRanges.length - 1}
                valueLabelFormat={(index) => discreteRanges[index]?.label}
                getAriaValueText={(index) => discreteRanges[index]?.label}
                style={{color: '#7F56D9'}}
            />
            <div style={{display: 'flex', justifyContent: 'space-between', fontSize: '12px', color: '#888'}}>
                <span>{discreteRanges[0].label}</span>
                <span>{discreteRanges[discreteRanges.length - 1].label}</span>
            </div>
        </div>
    );
};


export {MultiSelectFilter, SingleSelectFilter, RangeFilter};
