// src/config/VCPageConfig.js

export const VC_PAGE_CONFIG = {
    header: {
        logoSize: 60,
        titleField: "name",
        // descriptionField: "description",
    },
    sections: [
        {
            title: "Location",
            field: "location",
            position: "left",
            isLocation : true
        },
        {
            title: "Website",
            field: "website",
            isLink: true,
            position: "left"
        },
        {
            title: "LinkedIn",
            field: "social_links",
            isLink: true,
            position: "left"
        },
        {
            title: "About",
            field: "investment_thesis",
            position: "right"
        },
        // {
        //     title: "Top Portfolio Companies",
        //     field: "portfolio",
        //     isImageList: true,
        //     position: "right"
        // },
    ],
    contactTable: {
        columns: [
            { id: "name", label: "Name", minWidth: 190, width: 200, maxWidth: 290,isImage: true},
            { id: "position", label: "Position", minWidth: 250, width: 250, maxWidth: 300 },
            // { id: "summary", label: "LinkedIn Bio", minWidth: 250, width: 250, maxWidth: 300 },
            { id: "linkedin", label: "LinkedIn Profile", minWidth: 100, width: 100, maxWidth: 100, isLink: true },
            // { id: "image", label: "Profile Picture", minWidth: 50, width: 60, maxWidth: 80, isImage: true },
        ],
    },
};
