import { useState } from 'react';
import api from "../api";

const useShortlist = ( ) => {

    const toggleShortlist = async (vcId, action) => {
        try {
            await api.post('/data/update-shortlist', {vcId, action});
        } catch (error) {
            console.error("Error updating shortlist:", error);
        }
        ;
    };

    return {toggleShortlist}
}

export default useShortlist;
