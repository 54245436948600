import { useState } from 'react';

const useSearch = (onSearchSubmit , clearState) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [searchFocused, setSearchFocused] = useState(false);

    const handleSearchChange = (event) => setSearchTerm(event.target.value);

    const handleSearchSubmit = () => {
        onSearchSubmit(searchTerm);
        setSearchPerformed(true);
        setSearchFocused(false);
    };

    const clearSearch = () => {
        setSearchTerm('');
        onSearchSubmit('');
        setSearchPerformed(false);
        clearState();

    };

    return {
        searchTerm,
        setSearchTerm,
        searchPerformed,
        setSearchPerformed,
        searchFocused,
        setSearchFocused,
        handleSearchChange,
        handleSearchSubmit,
        clearSearch,
    };
};

export default useSearch;
