import { useEffect, useState } from 'react';

const useLoading = (initialLoading = false, timeout = 300, initialSpinner = false) => {
    const [isLoading, setIsLoading] = useState(initialLoading);
    const [showSpinner, setShowSpinner] = useState(initialSpinner);

    useEffect(() => {
        if (isLoading) {
            const timer = setTimeout(() => setShowSpinner(true), timeout);
            return () => clearTimeout(timer);
        } else {
            setShowSpinner(false);
        }
    }, [isLoading, timeout]);

    const startLoading = () => {
        setIsLoading(true); // This schedules an update
    };

    const stopLoading = () => {
        setIsLoading(false); // This schedules an update
    };

    return { showSpinner, startLoading, stopLoading };
};

export default useLoading;
