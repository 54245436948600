import React from 'react';
import { styled } from '@mui/system';
import { CiPause1 } from 'react-icons/ci';

const StyledButton = styled('button')(({ theme, isFocused }) => ({
    display: "flex",
    alignItems: "center",
    padding: "6px 12px",
    fontSize: "14px",
    fontWeight: 500,
    color: isFocused ? "#6B21A8" : "black",
    backgroundColor: isFocused ? "#F8F4FB" : "white",
    border: `1px solid ${isFocused ? "#D6BBFB" : "#ccc"}`,
    borderRadius: "8px",
    height: "40px",
    cursor: "pointer",
    outline: "none",
    textTransform: "none",
    transition: "background-color 0.3s, border 0.3s",
    '&:hover': {
        backgroundColor: "#F4EBFF",
        borderColor: "#D6BBFB",
        color: "#6B21A8",
    }
}));

const SetColumnsButton = ({ isFocused, onClick }) => (
    <StyledButton isFocused={isFocused} onClick={onClick}>
        <CiPause1 size={24} style={{ marginRight: "8px" }} />
        Set Columns
    </StyledButton>
);

export default SetColumnsButton;
