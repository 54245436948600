import { RiHome6Line } from "react-icons/ri";
import { FaRegHeart } from "react-icons/fa";
import { CiSettings } from "react-icons/ci";


const iconStyle = { fontSize: "1.5em" }

const sidebarButtons = [
    { icon: <RiHome6Line  style={iconStyle}/>, text: 'VC Index', link: '/' },
    { icon: <FaRegHeart  style={iconStyle}/>, text: 'Your List', link: '/shortlist' },
    { icon: <CiSettings  style={iconStyle}/>, text: 'Settings' , link: '/profile' }
];
export default sidebarButtons;
