import React, {useState, useEffect, useContext} from "react";
import {
    Box,
    Avatar,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LogoutIcon from "@mui/icons-material/Logout";
import api from "../api";
import {AuthContext} from "../context/AuthContext";
import LinkedInLoginButton from "../components/Users/LinkedinLogin"; // Your API instance
import userProfileImage from "../assets/images/person-min.jpg"
import useTrackEvent from '../hooks/useTrackEvent';

const UserSettings = () => {
    const { user ,logout, updateUser} = useContext(AuthContext);
    const name = user.name.split(' ');
    const [userData, setUserData] = useState({
        firstName: name[0],
        lastName: name[1],
        email: user.email,
        profileImage: user.logo,
    });
    const [file, setFile] = useState(null);
    const { trackAction } = useTrackEvent({})

    useEffect(() => {
        trackAction('page_view',
            { "page": "User Settings" });
    }, []);


    const handleLogout = () => {
        logout();
    }

    const handleFileUpload = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUserData((prev) => ({ ...prev, profileImage: reader.result }));
            };
            reader.readAsDataURL(uploadedFile);
            setFile(uploadedFile);
        }
    };




    const handleSaveChanges = async (e) => {
        e.preventDefault();
        const form = new FormData();
        form.append("firstName", userData.firstName);
        form.append("lastName", userData.lastName);
        if (file) {
            form.append("profileImage", file);
        }

        try {
            const response = await api.put("/user/update", form, {
                headers: {"Content-Type": "multipart/form-data"},
            });
            updateUser(JSON.stringify(response.data.user));
            alert("Profile updated successfully!");
        } catch (error) {
            console.error("Error updating user settings:", error);
            alert("Failed to update profile.");
        };
        trackAction('user_modify_personal_info',
            { "page": "User Settings",
                "firstName" : userData.firstName,
                "lastName": userData.lastName});

    };


    return (
        <Box sx={{pb :4}}>
            {/* Header Section */}
            <Box
                sx={{
                    height: "200px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 24px",
                    backgroundImage: `url(${userProfileImage})`, // Set the image as the background
                    backgroundSize: "cover", // Make the image cover the entire box
                    backgroundPosition: "center", // Center the image
                    backgroundRepeat: "no-repeat", // Prevent repetition
                }}
            >
                <Avatar
                    src={userData.profileImage}
                    alt="Profile"
                    sx={{
                        width: "120px",
                        height: "120px",
                        border: "4px solid white",
                        position: "absolute",
                        bottom: "-80px",
                        left: "24px",
                    }}
                />
            </Box>

            <Box style={{display: "flex", justifyContent: "space-between", marginTop : 10}}>
                <Box sx={{ ml: 20}}>
                    <Typography variant="h4" color="black" fontWeight="bold">
                        {`${userData.firstName} ${userData.lastName}`}
                    </Typography>
                    <Typography variant="body2" color="black">
                        {userData.email}
                    </Typography>
                </Box>
                <Button sx = {{mr:2, gap:1}}
                        onClick={handleLogout}
                >
                    <LogoutIcon />
                    Log Out
                </Button>
            </Box>

            <Paper
                elevation={3}
                sx={{
                    mt: 8,
                    p: 4,
                    borderRadius: "12px",
                    maxWidth: "700px",
                    border: "1px solid #EAECF0",
                    boxShadow: "none"
                }}
            >
                <Typography variant="h6" mb={2}>
                    Personal Info
                </Typography>
                <Typography variant="body2" color="textSecondary" mb={3}>
                    Update your photo and personal details.
                </Typography>
                <Grid container spacing={3}>
                    {/* Form Fields */}
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="First Name"
                            value={userData.firstName}
                            onChange={(e) =>
                                setUserData((prev) => ({
                                    ...prev,
                                    firstName: e.target.value,
                                }))
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            value={userData.lastName}
                            onChange={(e) =>
                                setUserData((prev) => ({
                                    ...prev,
                                    lastName: e.target.value,
                                }))
                            }
                        />
                    </Grid>

                    {/* Image Upload Section */}
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                border: "1px dashed #ccc",
                                borderRadius: "8px",
                                p: 3,
                                textAlign: "center",
                            }}
                        >
                            <label
                                htmlFor="file-upload"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    color: "#6c63ff",
                                }}
                            >
                                <CloudUploadIcon fontSize="large" />
                                <Typography variant="body2">
                                    Click to upload or drag and drop
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    SVG, PNG, JPG, or GIF (max. 800x400px)
                                </Typography>
                            </label>
                            <input
                                type="file"
                                id="file-upload"
                                style={{ display: "none" }}
                                onChange={handleFileUpload}
                            />
                        </Box>
                    </Grid>
                </Grid>

                {/* Action Buttons */}
                <Box
                    sx={{
                        mt: 4,
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 2
                    }}
                >
                    <Button variant="outlined">Cancel</Button>
                    <Button
                        variant="contained"
                        sx={{
                            color: "white",
                            background: "#6B21A8",
                            "&:hover": { background: "#5a55d8" },
                        }}
                        onClick={handleSaveChanges}
                    >
                        Save Changes
                    </Button>
                </Box>
            </Paper>

            {/* LinkedIn Section */}
            {!user.in_linkedin && (
            <Box sx={{ mt: 6, pl: 6 }}>
                <Typography variant="body1" mb={2}>
                    Make VentureLink more personal
                </Typography>
                <LinkedInLoginButton  />
            </Box> )}
        </Box>
    );
};

export default UserSettings;
