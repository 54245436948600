import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ChangePassword from './components/Users/ChangePassowrd';
import ProfilePage from './pages/UserSettings';
import AppLayout from './components/AppWrapper/AppLayout';
import TokenExpirationHandler from './components/TokenExpirationHandler';
import VCList from "./components/VC/VCList";
import AuthPage from "./components/Users/AuthPage";
import {ThemeProvider} from "@mui/material";
import customTheme from "./context/customTheme";
import VCPage from './components/VC/VCPage';
import UserSettings from "./pages/UserSettings";
import * as PropTypes from "prop-types";


function Redirect(props) {
    return null;
}

Redirect.propTypes = {to: PropTypes.string};
const App = () => {
  return (
      <Router>
          <ThemeProvider theme={customTheme}>
        <AuthProvider>
            <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<AuthPage isLogin={true} />} />
            <Route path="/signup" element={<AuthPage isLogin={false} />} />
                <Route element={<AppLayout />}>
                    <Route path={"/profile"} element={<UserSettings />} />
                    <Route path={"/change-password"} element={<ChangePassword />} />
                    <Route path={"/"} element={<VCList key="vcs" isShortlist={false} />} />
                    <Route path={"/shortlist"} element={<VCList key="shortlist" isShortlist={true} />} />
                    <Route path="/vc/:vcId" element={<VCPage />} />
                </Route>
          </Routes>
        </AuthProvider>
          </ThemeProvider>
      </Router>
  );
};

export default App;
