// config.js
export const COLUMNS = [
    { id: "name", label: "Name", sortable: true, isLogoColumn: true },
    { id: "checkSize", label: "Checks", sortable: false },
    { id: "stages", label: "Stages", sortable: false , tagWidth: 80},
    { id: "investment_thesis", label: "Investment Thesis", sortable: false },
    { id: "investments", label: "Investments", sortable: true },
    { id: "industry_focus", label: "Industry Focus", sortable: false ,tagWidth: 120 },
    { id: "geographical_focus", label: "Geographical Focus", sortable: false , tagWidth: 80},
    { id: "locations", label: "Headquarters Locations", sortable: false },
    { id: "social_links", label: "Social Links", sortable: false },
    { id: "in_shortlist", label: "Your Shortlist", sortable: false },


];

export const FILTER_CONFIG = {
    locations: { type: "multi-select", label: "Headquarters Locations", onlySearch: true },
    stages: { type: "multi-select", label: "Investment Stages" , onlySearch: false},
    industry_focus: { type: "multi-select", label: "Industry Focus", onlySearch: false },
    checkSize: { type: "range", label: "Check Size" , min: 0.1, max:200},
    geography: { type: "multi-select", label: "Geography", onlySearch: false },
};

export const DEFAULT_VISIBLE_COLUMNS = {
    name: true,
    in_shortlist: true,
    checkSize: true,
    stages: true,
    investments: false,
    assets_under_management: false,
    number_of_exits: false,
    investments_last_6_months: false,
    founded_year: false,
    social_links: false,
    industry_focus: true,
    geographical_focus: true,
    locations: true,
    avg_deal_closing_time: false,
};


// columnWidths.js

export const COLUMN_WIDTHS = {
    name: { minWidth: 100, width: 150, maxWidth: 200 },
    checkSize: { minWidth: 80, width: 100, maxWidth: 100 },
    stages: { minWidth: 130, width: 180, maxWidth: 180 },
    investments: { minWidth: 50, width: 50, maxWidth: 70 },
    investment_thesis: { minWidth: 200, width: 250, maxWidth: 300 },
    assets_under_management: { minWidth: 100, width: 120, maxWidth: 150 },
    number_of_exits: { minWidth: 100, width: 120, maxWidth: 150 },
    investments_last_6_months: { minWidth: 120, width: 150, maxWidth: 200 },
    founded_year: { minWidth: 80, width: 100, maxWidth: 120 },
    social_links: { minWidth: 100, width: 120, maxWidth: 140 },
    industry_focus: { minWidth: 120, width: 180, maxWidth: 180 },
    geographical_focus: { minWidth: 120, width: 145, maxWidth: 150 },
    locations: { minWidth: 90, width: 90, maxWidth: 180 },
    avg_deal_closing_time: { minWidth: 100, width: 120, maxWidth: 150 },
    in_shortlist: { minWidth: 50, width: 50, maxWidth: 70 } // For Shortlist Icon
};

export const pastelColors = [
    "#FFAB91", // Light Coral
    "#AED581", // Light Green
    "#81C784", // Vibrant Green
    "#80CBC4", // Vibrant Teal
    "#4FC3F7", // Sky Blue
    "#64B5F6", // Soft Blue
    "#7986CB", // Soft Purple
    "#9575CD", // Medium Purple
    "#F06292", // Rose Pink
    "#E57373", // Salmon
];
