import { useCallback, useEffect, useRef } from 'react';
import { trackEvent } from '../utils/mixpanel'; // Import your Mixpanel tracking utility

const useTrackEvent = (defaultProperties = {}, tableSpecificProperties = {}) => {
    // Merge user-defined defaults with base defaults for action properties
    const baseDefaultProperties = {};
    const actionPropertiesRef = useRef({ ...baseDefaultProperties, ...defaultProperties });

    // Merge user-defined defaults with base defaults for table-specific properties
    const baseTableProperties = { page: 'Unknown', table: 'Unknown', number_of_results: 'Unknown' };
    const tablePropertiesRef = useRef({ ...baseTableProperties, ...tableSpecificProperties });

    useEffect(() => {
        actionPropertiesRef.current = { ...baseDefaultProperties, ...defaultProperties };
    }, [defaultProperties]);

    useEffect(() => {
        tablePropertiesRef.current = { ...baseTableProperties, ...tableSpecificProperties };
    }, [tableSpecificProperties]);

    const trackAction = useCallback((actionName, additionalProperties = {}) => {
        trackEvent(actionName, {
            ...actionPropertiesRef.current,
            ...additionalProperties,
        });
    }, []);

    const trackTableInteraction = useCallback((actionName, additionalProperties = {}) => {
        trackEvent(actionName, {
            ...tablePropertiesRef.current,
            ...additionalProperties,
        });
    }, []);

    return { trackAction, trackTableInteraction };
};

export default useTrackEvent;
