// src/components/ChangePassword.js
import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const ChangePassword = () => {
    const { changePassword } = useContext(AuthContext);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        changePassword(oldPassword, newPassword);
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Change Password</h2>
            <input
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Old Password"
                required
            />
            <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
                required
            />
            <button type="submit">Change Password</button>
        </form>
    );
};

export default ChangePassword;
