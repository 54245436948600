import React, {useContext} from 'react';

import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import {AuthContext} from "../../context/AuthContext";
import { v4 as uuidv4 } from 'uuid';
import api from "../../api";


const LinkedInLoginButton = () => {
    const handleLogin = async () => {
        try {
            const response = await api.get(`/auth/linkedin/data`, {
                credentials: 'include', // Include cookies in the request
            });

            await response;
            if (response.data === "True") {
                login(null, null, 'linkedin', 'linkedin')
            }

            if (response.data === "False") {
                console.warn('LinkedIn session expired, re-authenticating...');
                openLinkedInPopup(); // Open LinkedIn popup for re-login
            }
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };


    const { login } = useContext(AuthContext);
    const openLinkedInPopup = () => {
        const clientId = '77insx0uf7z1ci';
        const redirectUri = `${process.env.REACT_APP_REDIRECT_URL }/linkedin`;
        const state = uuidv4(); // Generate a unique state value
        const scope = 'openid profile email';
        const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;

        const width = 600;
        const height = 600;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        const popup = window.open(authUrl, '_blank', `width=${width},height=${height},top=${top},left=${left}`);

        const popupInterval = setInterval(() => {
            if (!popup || popup.closed) {
                clearInterval(popupInterval);
            } else {
                try {
                    const popupUrl = new URL(popup.location);
                    if (popupUrl.searchParams.get('code')) {
                        const code = popupUrl.searchParams.get('code');
                        popup.close();
                        clearInterval(popupInterval);
                        handleAuthCode(code);
                    }
                } catch (err) {
                    // Ignore cross-origin errors until popup is redirected back to our domain
                }
            }
        }, 500);
    };

    const handleAuthCode = async (code) => {
        try {
            login(null, null, code, 'linkedin');
        } catch (error) {
            console.error('LinkedIn Login failed', error);

        } finally {
        }
    };

    return (
        <div>
            <img
                onClick={handleLogin}
                src={linkedin}
                alt="Sign in with Linked In"
                style={{
                    maxWidth: '360px',
                    cursor: 'pointer',
                    height: '44px',
                    marginBottom :'12px'

                }}
            />
        </div>
            );
            };

            export default LinkedInLoginButton;

