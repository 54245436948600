import React, { createContext, useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from '../api';
import {identifyUser} from "../utils/mixpanel";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const storedUser = localStorage.getItem('user');
    const [user, setUser] = useState(JSON.parse(storedUser));
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            // Identify the user in Mixpanel
            identifyUser(user);
        }
    }, [user]);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const updateUser = (data) => {
        localStorage.setItem('user', data)
        setUser(JSON.parse(storedUser));
    }

    const login = async (email, password, credential, provider) => {
        try {
            let response;

            // If credential is provided, perform Google login
            if (provider === 'google' && credential) {
                response = await axios.post('/auth/google', { token: credential });
            }
            else if (provider === 'linkedin' && credential) {
                response = await axios.post('/auth/linkedin', { code: credential });
            }
            // Otherwise, perform standard email/password login
            else if (email && password) {
                response = await axios.post('/auth/login', { email, password });
            }
            else {
                throw new Error("Invalid login attempt");
            }

            // Handle the response and store tokens
            const { access_token, user } = response.data;

            localStorage.setItem('access_token', access_token);
            localStorage.setItem('user', JSON.stringify(user));
            setUser(user);
            navigate('/');
        } catch (error) {
            console.error("Login failed", error);
        }
    };


    const signup = async (email, password) => {
        try {
            await axios.post('/auth/signup', { email, password });
            await login(email, password, null);
        } catch (error) {
            console.error("Signup failed", error);
        }
    };

    const logout = async () => {
        try {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
            await axios.post('/auth/logout');
            setUser(null);
            navigate('/login');
        } catch (error) {
            console.error("Logout failed", error);
        }
    };

    const changePassword = async (oldPassword, newPassword) => {
        try {
            await axios.post('/auth/change-password', { oldPassword, newPassword });
            alert('Password changed successfully');
        } catch (error) {
            console.error("Change password failed", error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, signup, logout, changePassword, updateUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
