import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Button = ({link, text, style}) => {

    const defaultButtonStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 16px',
        gap: '6px',
        width: '83px',
        height: '44px',
        borderRadius: '8px',
    };

    const combinedStyle = { ...defaultButtonStyle, ...style };



    return (
        <Link to={link} style={{ textDecoration: 'none' }}>
            <button style={combinedStyle}>
                {text}
            </button>
        </Link>
    );
};



export default Button;
