import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import './NavItem.css';

const NavItem = ({ icon, text, to }) => {
    const location = useLocation(); // Get the current route
    const isActive = location.pathname === to;
    return (
        <Link to={to} className={`nav-item ${isActive ? "active" : ""}`}>
            <div className="icon">
                {icon}
            </div>
            <span className="text">{text}</span>
        </Link>
    );
};

export default NavItem;
