import { useState } from 'react';

const usePagination = ( {initialPage = 1,initialPageSize = 20} ) => {
    const [page, setPage] = useState(initialPage);
    const pageSize = initialPageSize;


    const handlePageChange = newPage => {
        setPage(newPage);
    };


    return {
        page,
        pageSize,
        handlePageChange,
    };
};

export default usePagination;
