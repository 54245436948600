// src/api.js
import axios from 'axios';

const apiURL = process.env.REACT_APP_API_URL;
const api = axios.create({
    baseURL: apiURL, // Flask backend
    withCredentials: true, // Allow sending cookies (for refresh token in httpOnly)
});

// Request interceptor to add Authorization header with access token
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle token refresh logic
api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // If access token has expired and we receive a 401 error
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                // Attempt to refresh the token
                const { data } = await axios.post(
                    `${apiURL}/auth/token/refresh`,
                    {},
                    { withCredentials: true } // Ensure we send the httpOnly cookie
                );

                // Store the new access token in localStorage
                localStorage.setItem('access_token', data.access_token);


                // Update the original request's Authorization header with the new token
                originalRequest.headers['Authorization'] = `Bearer ${data.access_token}`;

                // Retry the original request with the new token
                return api(originalRequest);
            } catch (refreshError) {
                // If refresh fails, log out the user
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                window.location.href = '/login'; // Redirect to login on failure
            }
        }

        return Promise.reject(error);
    }
);

export default api;
