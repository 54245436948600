export const isActiveFilter = (filterKey, filterConfig, filters, rangeValues) => {
    const filterValue = filters[filterKey];

    if (filterConfig.type === 'range') {
        return (
            Array.isArray(filterValue) &&
            (filterValue[0] !== rangeValues[filterKey][0]|| filterValue[1] !== rangeValues[filterKey][1])
        );
    }

    // For other types (e.g., multi-select), check if there is a selected value
    return Array.isArray(filterValue) ? filterValue.length > 0 : Boolean(filterValue);
};
