import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

// Styled TextField with conditional styling for focus
const StyledTextField = styled(TextField)(({ theme, focused }) => ({
    width: '320px',
    borderRadius: '8px',
    backgroundColor: focused ? '#F8F4FB' : '#fff',
    transition: 'background-color 0.3s, border-color 0.3s',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#ccc', // Default border color
        },
        '&:hover fieldset': {
            borderColor: '#D6BBFB', // Hover border color
        },
        '&.Mui-focused fieldset': {
            borderColor: '#D6BBFB', // Focused border color (purple)
        },
    }
}));

const SearchBar = ({ searchTerm, onSearchChange, onSearchSubmit, onClearSearch, focused, setFocused,searchPerformed }) => {

    return (
        <StyledTextField
            placeholder="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={onSearchChange}
            onFocus={() => setFocused(true)}
            onBlur={() => {
                // Reset focus state only if no search is performed
                if (!searchPerformed && searchTerm === '') {
                    setFocused(false);
                }
            }}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    onSearchSubmit();
                }
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {searchTerm && searchPerformed ? (
                            <IconButton
                                onMouseDown={onClearSearch} // Use onMouseDown to prevent losing focus
                                style={{
                                    borderRadius: "50%",
                                    padding: "4px"
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        ) : focused ? (
                            // Search button if focused and no search was performed
                            <IconButton
                                onMouseDown={onSearchSubmit} // Use onMouseDown to prevent losing focus
                                style={{
                                    backgroundColor: "#D6BBFB",
                                    borderRadius: "50%",
                                    padding: "4px",
                                    color: "#6B21A8"
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                        ) : null
                        }
                    </InputAdornment>
                ),
            }}
            focused={focused} // Pass the focused state for conditional styling
        />
    );
};

export default SearchBar;
