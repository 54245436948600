import { useState } from 'react';

const useColumnVisibility = (defaultColumns ) => {
    const [visibleColumns, setVisibleColumns] = useState(defaultColumns);
    const [anchorEl, setAnchorEl] = useState(null);

    const toggleColumnVisibility = (columnId) => {
        setVisibleColumns((prev) => ({ ...prev, [columnId]: !prev[columnId] }));
    };

    const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
    const handleCloseMenu = () => setAnchorEl(null);

    return {
        visibleColumns,
        anchorEl,
        handleOpenMenu,
        handleCloseMenu,
        toggleColumnVisibility,
    };
};

export default useColumnVisibility;
