import React, {useContext} from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {AuthContext} from "../../context/AuthContext";


const CLIENT_ID = '817361183906-38jgod0igu1mln9pm6457vgpj68u1hee.apps.googleusercontent.com';
const GoogleLoginButton = () => {
    const { login } = useContext(AuthContext);
    const handleGoogleLoginSuccess = async (credentialResponse) => {
        try {
            const { credential } = credentialResponse;
            // Send Google credential to Flask backend
            login(null, null, credential, 'google');
        } catch (error) {
            console.error('Google Login failed', error);
        }
    };

    return (
        <div className={"google-login"}>
        <GoogleOAuthProvider clientId={CLIENT_ID}>
            <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={() => console.log('Google Login Failed')}
                width={236}

            />
        </GoogleOAuthProvider>
        </div>
    );
};

export default GoogleLoginButton;
