import React, { useState } from 'react';
import {Button, Chip, Popover, styled, Typography} from '@mui/material';
import { FILTER_CONFIG } from './config';
import { MultiSelectFilter, SingleSelectFilter, RangeFilter } from '../Utils/FilterComponents';
import { isActiveFilter } from '../Utils/filterUtils';


const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'menuOpen',
})(({ theme, isActive , menuOpen}) => ({
    borderRadius: 8,
    backgroundColor: isActive ? "#7F56D9" : menuOpen ? "#FFFFFF"  : "transparent", // Purple if active, transparent if not
    color: isActive ? "#FFFFFF" : menuOpen ? "#333" : "#333", // White text if active, dark text otherwise
    border: isActive ? "1px solid #6B21A8" : menuOpen ? "1px solid #ccc" : "1px solid transparent",
    transition: "background-color 0.3s, color 0.3s",
    '&:hover': {
        backgroundColor: isActive ? "#7F56D9" : "#FFFFFF", // White on hover if not active
        color: isActive ? "#FFFFFF" : "#333", // Dark text if hovered but not active
        border :'none' , // Light gray border on hover if not active
    },
}));


const FilterLine = ({ filters, onFilterChange, onClearAll, rangeValues }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentFilter, setCurrentFilter] = useState(null);
    const [temporaryFilterValues, setTemporaryFilterValues] = useState({});
    const [menuOpen, setMenuOpen] = useState(false); // Track menu open state




    const handleFilterClick = (event, filterKey, filterConfig) => {
        setAnchorEl(event.currentTarget); // Set the anchor element for the Popover
        setCurrentFilter({ key: filterKey, config: filterConfig });
        setTemporaryFilterValues(filters[filterKey] || []); // Copy current values to temporary state
        setMenuOpen(true); // Set menu open state to true

    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentFilter(null);
        setMenuOpen(false); // Set menu open state to true
    };

    const handleApply = () => {
        // Apply the selected filter and reset popover state
        onFilterChange(currentFilter.key, temporaryFilterValues);
        handleClose();
    };

    const handleTemporaryFilterChange = (value) => {
        // Update temporary values as user interacts with the filter component
        setTemporaryFilterValues(value);
    };

    const handleClearSelection = () => {
        const defaultValues = currentFilter.config.type === 'range'
            ? [FILTER_CONFIG[currentFilter.key]?.min, FILTER_CONFIG[currentFilter.key]?.max]
            : [];
        onFilterChange(currentFilter.key, defaultValues);
        handleClose();
    };


    const renderFilterChip = (filterKey, filterConfig) => {
        const isActive = isActiveFilter(filterKey, filterConfig, filters, rangeValues);
        const label = filterConfig.label;
        const selectedCount = Array.isArray(filters[filterKey]) && filterConfig.type !== 'range' ? filters[filterKey].length : null;
        const shownLabel = selectedCount ? `${label} (${selectedCount})` : label

        return (
            <StyledChip
                key={filterKey}
                label={
                        <Typography variant={"T2"}>
                            {shownLabel}
                        </Typography>}
                clickable
                isActive={isActive}
                menuOpen={menuOpen && currentFilter?.key === filterKey} // Pass menuOpen as true only if this chip's menu is open
                onClick={(event) => handleFilterClick(event, filterKey, filterConfig)}
                style={{ margin: '0 8px'}}
            />
        );
    };

    const renderFilterComponent = () => {
        if (!currentFilter) return null;
        const { key, config } = currentFilter;
        switch (config.type) {
            case 'multi-select':
                return (<div>

                    <MultiSelectFilter
                        label={config.label}
                        filterType={key}
                        selectedOptions={temporaryFilterValues}
                        appliedOptions={filters[key]} // Pass final applied filters for sorting
                        onChange={handleTemporaryFilterChange}
                        onlySearch={config.onlySearch}
                    />
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px', gap:'8px'}}>
                            <Button onClick={handleClearSelection} variant={'outlined'} style={{color :"#7F56D9", borderColor : "#7F56D9"}}>
                                Clear Selection
                            </Button>
                            <Button onClick={handleApply} variant="contained"  style={{backgroundColor: "#7F56D9", color:"white"}}>
                                Apply
                            </Button>

                        </div>
                    </div>
                );
            case 'single-select':
                return (
                    <SingleSelectFilter
                        label={config.label}
                        filterType={key}
                        selectedOption={filters[key] || ''}
                        onChange={(value) => {
                            onFilterChange(key, value);
                            handleClose();
                        }}
                    />
                );
            case 'range':
                return (
                    <div>
                        <RangeFilter
                            label={config.label}
                            filterType={key}
                            selectedRange={filters[key] || [rangeValues[key]?.min, rangeValues[key]?.max]}
                            onChange={handleTemporaryFilterChange}
                        />
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px', gap: '8px'}}>
                            <Button onClick={handleClearSelection} variant={'outlined'}
                                    style={{color: "#7F56D9", borderColor: "#7F56D9"}}>
                                Clear Selection
                            </Button>
                            <Button onClick={handleApply} variant="contained"
                                    style={{backgroundColor: "#7F56D9", color: "white"}}>
                                Apply
                            </Button>

                        </div>
                    </div>
            )
                ;

            default:
                return null;
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between', // Position chips and "Clear All" button
                marginBottom: '16px',
                backgroundColor: '#FDF2FA',
                borderRadius: '8px',
                padding: '4px', // Add padding to align items better
            }}
        >
            <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>

                <Typography variant="T3" style={{color: '#667085', marginLeft: '12px'}}>Filter by:</Typography>
                {Object.entries(FILTER_CONFIG).map(([filterKey, filterConfig]) =>
                    renderFilterChip(filterKey, filterConfig)
                )}
            </div>
                <Button variant="text" onClick={onClearAll} style={{
                    color: '#333',
                    height: '32px', // Match the chip height
                    padding: '4px 12px', // Ensure consistent padding
                    borderRadius: '8px', // Optional: match the chip border radius
                    marginRight: '12px'
                }}>Clear all</Button>

                {/* Filter Popover */}
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div style={{padding: '16px', minWidth: '200px'}}>
                        {renderFilterComponent()}
                    </div>
                </Popover>
            </div>
            );
            };

            export default FilterLine;
