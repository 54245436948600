import React from "react";
import "../VC/VCListTable.css";

const PaginationTable = ({ totalPages, page, handlePageChange }) => {
    const getPaginationRange = () => {
        const pages = [];
        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) pages.push(i);
        } else {
            const startPages = [1];
            const endPages = [totalPages];
            if (page <= 2) {
                pages.push(...startPages, 2,3, "...", ...endPages);
            } else if (page === 3 ) {
                pages.push(...startPages, 2,3,4, "...", ...endPages);
            } else if (page >= totalPages -1 ) {
                pages.push(...startPages, "...", totalPages - 2,totalPages - 1, ...endPages);
            } else if (page === totalPages -2 ) {
                pages.push(...startPages, "...",totalPages - 3, totalPages - 2,totalPages - 1, ...endPages);
            } else {
                pages.push(...startPages, "...", page - 1, page, page + 1, "...", ...endPages);
            }
        }
        return pages;
    };

    const paginationRange = getPaginationRange();

    return (
        <div className="pagination-container">
            {/* Previous Button */}
            <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
                className="pagination-button"
            >
                <span className="arrow-left">←</span> Previous
            </button>

            {/* Page Number Buttons */}
            <div className="pagination-numbers">
                {paginationRange.map((p, index) => (
                    <button
                        key={index}
                        onClick={() => typeof p === "number" && handlePageChange(p)}
                        disabled={p === page || p === "..."}
                        className={`pagination-number ${p === page ? "active" : ""}`}
                    >
                        {p === "..." ? "..." : p}
                    </button>
                ))}
            </div>

            {/* Next Button */}
            <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages}
                className="pagination-button"
            >
                Next <span className="arrow-right">→</span>
            </button>
        </div>
    );
};

export default PaginationTable;
