import api from "../api";
import {useContext} from "react";
import {AuthContext} from "../context/AuthContext";

export const IdentifyUserUsageFunnel = async (user, eventName) => {
    if (!user?.id) {
        console.warn('User data is missing or invalid.');
        return;
    }

    const EVENT_TO_FUNNEL_STAGE = {
        register: "register",
        page_view: "navigated",
        table_: "action_on_table",
        page_v: "viewed VC page",
        external_link_clicked: "action_on_table"
        // Add more stages as needed
    };

    const currentStage = user.usage_funnel;
    const newStage = EVENT_TO_FUNNEL_STAGE[eventName];

    if (!newStage) {
        console.warn(`Event "${eventName}" does not map to a funnel stage.`);
        return;
    }

    // Ensure chronological progression
    const stages = Object.values(EVENT_TO_FUNNEL_STAGE);
    const currentIndex = stages.indexOf(currentStage);
    const newIndex = stages.indexOf(newStage);

    if (newIndex > currentIndex) {
        try {
            // Update the user's funnel stage in the database
            await UpdateUsageFunnelInDB(user.id, newStage);

            console.log(`User ${user.id} updated to funnel stage: ${newStage}`);
        } catch (error) {
            console.error("Failed to update usage funnel in DB:", error);
        }
    }
};

// Mock function to update the funnel stage in the database
const UpdateUsageFunnelInDB = async (userId, newFunnelStage) => {
    const {updateUser} = useContext(AuthContext);

    // Replace with actual API or database logic
    const form = new FormData();
    form.append("usage_funnel", newFunnelStage);

    try {
        const response = await api.put("/user/update", form, {
            headers: {"Content-Type": "multipart/form-data"},
        });
        updateUser(JSON.stringify(response.data.user));
        alert("Profile updated successfully!");
    } catch (error) {
        console.error("Error updating user settings:", error);
        alert("Failed to update profile.");
    };
    console.log(`Simulating DB update for user ${userId} to ${newFunnelStage}`);
};
