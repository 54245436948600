import React, { useState, useEffect, useRef } from 'react';
import api from '../../api';
import DataTable from '../Data/DataTable';
import defaultVCLogo from '../../assets/images/VC_default _logo.jpg';
import SearchBar from '../Data/searchBar';
import SetColumnsButton from '../Data/SetColumnsButton';
import ColumnsDropdownMenu from '../Data/ColumnsDropdownMenu';
import FilterLine from './FilterLine';
import PaginationTable from '../Utils/PaginationTable';
import { COLUMNS, DEFAULT_VISIBLE_COLUMNS, pastelColors, COLUMN_WIDTHS, FILTER_CONFIG } from './config';
import {CircularProgress, Tooltip, IconButton, Typography} from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import usePagination from  '../../hooks/usePagination'
import useColumnVisibility from "../../hooks/useColumnVisibility";
import useFilters from "../../hooks/useFilters";
import useSearch from "../../hooks/useSearch";
import useLoading from "../../hooks/useLoading";
import useShortlist from "../../hooks/useShorlist";
import {useNavigate, useLocation} from 'react-router-dom';
import {isActiveFilter} from "../Utils/filterUtils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useTrackEvent from '../../hooks/useTrackEvent';


const VCList = ({ isShortlist }) => {
    const navigate = useNavigate(); // Initialize the navigate hook
    const location = useLocation();
    const [vcs, setVcs] = useState([]);
    const {page, pageSize, handlePageChange } = usePagination({});
    const [totalPages, setTotalPages] = useState(1);
    const [totalVcs, setTotalVcs] = useState(0);
    const {showSpinner, startLoading, stopLoading} = useLoading(300);
    const [sortOrder, setSortOrder] = useState({ field: 'tier', direction: 'asc' });
    const [defaultSort, setDefaultSort] = useState(true);
    const {visibleColumns, anchorEl, handleOpenMenu, handleCloseMenu, toggleColumnVisibility} = useColumnVisibility(DEFAULT_VISIBLE_COLUMNS)
    const {filters, setFilters,rangeValues, updateFilter, clearAllFilters} = useFilters( FILTER_CONFIG,
        (resetFilters)=>      {navigate(".", { replace: true, state: { searchTerm: searchTerm, filters: resetFilters, page: 1, searchPerformed: searchPerformed }})}
    )
    const {toggleShortlist} = useShortlist();
    const {searchTerm,setSearchTerm, searchPerformed,setSearchPerformed,  searchFocused, setSearchFocused, handleSearchChange, handleSearchSubmit, clearSearch} = useSearch((term) => {
        // Fetch VCs whenever search is submitted or cleared
        handlePageChange(1); // Reset to page 1 on search
        fetchVcs(term);
    }, ()=>      {navigate(".", { replace: true, state: { searchTerm: "", filters: filters, page: 1, searchPerformed: false }})}
    )
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);


    const fetchVcs = async ( search = searchTerm ) => {
        startLoading();
        try {
            const response = await api.get('/data/vcs', {
                params: {
                    page,
                    page_size: pageSize,
                    search: search,
                    sort_by: defaultSort ? 'tier' :  sortOrder.field,
                    sort_order: defaultSort ? 'asc' : sortOrder.direction,
                    shortlist: isShortlist ? 1 : 0,
                    ...filters,
                },
            });
            setVcs(response.data.items);
            setTotalPages(response.data.total_pages);
            setTotalVcs(response.data.total_vcs);
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            stopLoading();
        }
    };

    const page_view = isShortlist ? 'My List' : 'VC Index';
    const { trackAction, trackTableInteraction } = useTrackEvent({}, {
        page: page_view, // Current page
        table: "VC table",
        number_of_results :totalVcs, // Default property for table-related actions
    });

    useEffect(() => {
        if (initialLoadComplete) {
            fetchVcs();
        }
    }, [initialLoadComplete, page, sortOrder, filters]);

    useEffect(() => {
        trackAction('page_view',
            { "page": page_view });
    }, []);


    const handleFilterChangeWithTracking = (filterKey, filterValue) => {
        updateFilter(filterKey, filterValue);
        trackTableInteraction('table_filter_interaction', { filterKey, filterValue });
    };

    const clearAllFiltersWithTracking = () => {
        clearAllFilters();
        trackTableInteraction('table_clear_filters_interaction');
    };

    const handlePageChangeWithTracking = (newPage) => {
        handlePageChange(newPage);
        trackTableInteraction('table_pagination_interaction', { "table_page" : newPage});
    };

    const handleSearchSubmitWithTracking = (term) => {
        handleSearchSubmit(term);
        trackTableInteraction('table_search_interaction', { "search_term": term });
    };

    const toggleColumnVisibilityWithTracking = (visibleColumns) => {
        toggleColumnVisibility(visibleColumns);
        trackTableInteraction('table_columns_visibility_toggle', { "visible_columns": visibleColumns });
    };

    useEffect(() => {
        if (location.state) {
            // Restore the state passed from VCPage
            const { searchTerm, filters, page, searchPerformed } = location.state;
            setSearchTerm(searchTerm || '');
            setFilters(filters || {});
            handlePageChange(page || 1);
            setSearchPerformed(searchPerformed || false);
            setInitialLoadComplete(true);
        } else {
            setInitialLoadComplete(true);
        }
    }, [location.state]);

    const handleShortlist = async (vcId, inShortlist) => {
        const action = inShortlist ? 'remove' : 'add';
        trackTableInteraction('vc_shortlist_add_remove', {"action": action, "VC Id": vcId})
        await toggleShortlist(vcId, action)
        setVcs(prevVcs => prevVcs.map(vc => vc.id === vcId ? { ...vc, in_shortlist: !inShortlist } : vc));
    };

    const handleSortChange = field => {
        const isAsc = sortOrder.field === field && sortOrder.direction === "asc";
        trackTableInteraction('table_sort_interaction', { sortField: field, sortOrder: sortOrder.direction });
        setSortOrder({ field, direction: isAsc ? "desc" : "asc" });
        setDefaultSort(false)
        handlePageChange(1);
    };

    const renderCellContent = (row, column, width) => {
        if (column.isLogoColumn) {
            const logoUrl = row.logo ? row.logo : defaultVCLogo;
            return (
                <div style={{display: "flex", alignItems: "center"}}
                >
                    <img
                        src={logoUrl}
                        alt={`${row.name} logo`}
                        onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop in case the fallback image also fails
                            e.target.src = defaultVCLogo;
                        }}
                        style={{width: 30, height: 30, marginRight: 8, borderRadius: "50%"}}
                    />
                    <Typography
                        sx={{
                            cursor: "pointer",
                            "&:hover": {
                                color: "#6941C6", // Hover color
                            },
                            transition: "color 0.3s", // Smooth transition
                        }}
                        onClick={() => handleVCClick(row.id)}
                        variant="T2"
                    >
                        {row.name}
                    </Typography>
                </div>
            );
        }

        if (Array.isArray(row[column.id])) {

            return renderTags(row[column.id], width);
        }
        if (column.id === 'social_links')
            return (
                <a href={row.social_links} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', fontStyle: 'bold', fontFamily: 'inter'}}>
                        <Typography variant="T4"> LinkedIn: </Typography>  <OpenInNewIcon color="primary"  fontSize='small' sx={{mb : '-5px'}} />
                    </a>
            )

        if (column.id === 'in_shortlist') {
            return (
                <IconButton onClick={() => handleShortlist(row.id, row.in_shortlist)}>
                    {row.in_shortlist ? (
                        <FavoriteIcon style={{ color: "red" }} />
                    ) : (
                        <FavoriteBorderIcon />
                    )}
                </IconButton>
            );
        }

        return <Typography variant='T3'> {row[column.id]}</Typography>;
    };

// Generate a consistent pastel color for a tag
    const generateColorForTag = (tag) => {
        let hash = 0;
        for (let i = 0; i < tag.length; i++) {
            hash = tag.charCodeAt(i) + ((hash << 5) - hash);
        }
        return pastelColors[Math.abs(hash) % pastelColors.length];
    };

    const renderTags = (tags, width) => {
        const container = document.createElement("div");
        container.style.visibility = "hidden";
        container.style.position = "absolute";
        container.style.whiteSpace = "nowrap";
        document.body.appendChild(container);

        let currentWidth = 0;
        let visibleTags = [];

        // Handle the first tag separately
        if (tags.length > 0) {
            container.innerHTML = `<span style="padding: 4px 8px; margin-right: 4px; display: inline-block;">${tags[0]}</span>`;
            const firstTagWidth = container.offsetWidth;

            if (firstTagWidth > width) {
                // Break the first tag into a maximum of two lines
                const words = tags[0].split(" ");
                let multilineTag = "";
                let lineWidth = 0;
                let lines = [];
                let currentLine = "";

                words.forEach((word) => {
                    container.innerHTML = `<span>${currentLine ? currentLine + " " : ""}${word}</span>`;
                    const wordWidth = container.offsetWidth;

                    if (lineWidth + wordWidth > width) {
                        if (lines.length < 1) {
                            lines.push(currentLine.trim());
                            currentLine = word; // Start a new line
                            lineWidth = wordWidth;
                        } else if (lines.length === 1) {
                            // Add up to the second line and stop further splitting
                            currentLine += (currentLine ? " " : "") + word;
                            lineWidth += wordWidth;
                        }
                    } else {
                        currentLine += (currentLine ? " " : "") + word;
                        lineWidth += wordWidth;
                    }
                });

                // Push the last line if it exists
                if (currentLine && lines.length < 2) {
                    lines.push(currentLine.trim());
                }

                multilineTag = lines.join("\n"); // Join the lines with a newline character
                visibleTags.push(multilineTag);
                currentWidth = width; // Assume the entire width is used for the first tag
            } else {
                visibleTags.push(tags[0]);
                currentWidth += firstTagWidth;
            }
        }

        // Handle the remaining tags
        for (let i = 1; i < tags.length; i++) {
            const tag = tags[i];
            container.innerHTML = `<span style="padding: 4px 8px; margin-right: 4px; display: inline-block;">${tag}</span>`;
            const tagWidth = container.offsetWidth;

            if (currentWidth + tagWidth <= width) {
                currentWidth += tagWidth;
                visibleTags.push(tag);
            } else {
                break;
            }
        }

        const extraTags = tags.length - visibleTags.length;
        document.body.removeChild(container);

        return (
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
                {visibleTags.map((tag, index) => (
                    <span
                        key={index}
                        style={{
                            marginRight: "4px",
                            backgroundColor: generateColorForTag(tag),
                            padding: "4px 8px",
                            borderRadius: "4px",
                            color: "#fff",
                            whiteSpace: index === 0 ? "pre-wrap" : "nowrap", // Only wrap the first tag
                            wordBreak: index === 0 ? "break-word" : "normal", // Only break words for the first tag
                            maxWidth: index === 0 ? `${width}px` : "none", // Restrict width for the first tag
                            overflow: "hidden", // Prevent overflow
                            textOverflow: "ellipsis", // Add ellipsis if it still overflows
                        }}
                    >
                    {tag}
                </span>
                ))}
                {extraTags > 0 && (
                    <Tooltip title={tags.join(", ")}>
                    <span style={{ marginLeft: "4px", color: "#888", cursor: "pointer" }}>
                        +{extraTags}
                    </span>
                    </Tooltip>
                )}
            </div>
        );
    };



    const isFilterActive = () => {
        return Object.entries(FILTER_CONFIG).some(([filterKey, filterConfig]) =>
            isActiveFilter(filterKey, filterConfig, filters, rangeValues)
        );
    };

    const handleVCClick = (vcId) => {
        // Gather the current state to pass
        const currentState = {
            searchTerm,
            filters,
            page,
            searchPerformed
        };
        navigate(`/vc/${vcId}`, {state: currentState});
    };


    return (
        <div>
            {isShortlist ? (
                <div>
                    <div style={{ marginBottom :'6px'}} ><Typography variant="T0" style={{fontSize:'20px'}}>Your list</Typography></div>
                    <div><Typography variant="T3"  >{totalVcs} VCs listed</Typography></div>
                </div>
            ) : (searchPerformed || isFilterActive()) ? (
                    <div style={{ marginBottom :'6px'}}>
                        <div style={{ marginBottom :'6px'}}><Typography variant="T0" style={{fontSize:'20px'}}>VC Index</Typography></div>
                        <div><Typography variant="T3">Showing {totalVcs} VC results</Typography></div>
                    </div>
            ): (
                <div style={{ marginBottom :'6px'}} >
                    <div style={{ marginBottom :'6px'}}><Typography variant="T0" style={{fontSize:'20px'}}>VC Index</Typography></div>
                    <div><Typography variant="T3">8,180 Top VCs from around the world, enriched and filterable</Typography></div>
                </div>
            )}
            <div style={{display: "flex", alignItems: "center", marginBottom: "36px", marginTop: "36px"}}>
                <div>
                <SearchBar
                    searchTerm={searchTerm}
                    onSearchChange={handleSearchChange}
                    onSearchSubmit={handleSearchSubmitWithTracking}
                    onClearSearch={clearSearch}
                    focused={searchFocused}
                    setFocused={setSearchFocused}
                    searchPerformed={searchPerformed}
                />
                </div>


                <div style={{marginLeft: "auto"}}>

                    <SetColumnsButton
                        isFocused={Boolean(anchorEl)}
                        onClick={anchorEl ? handleCloseMenu : handleOpenMenu}
                    />
                </div>
                </div>

                <FilterLine
                    filters={filters}
                    onFilterChange={handleFilterChangeWithTracking}
                    onClearAll={clearAllFiltersWithTracking}
                    rangeValues={rangeValues}
                />
                {showSpinner ? (
                    <div style={{display: 'flex', justifyContent: 'center', padding: '16px'}}>
                        <CircularProgress size={48}/>
                    </div>
                ) : (
                    <DataTable
                        columns={COLUMNS}
                        data={vcs}
                        visibleColumns={visibleColumns}
                        sortOrder={sortOrder}
                        onSortChange={handleSortChange}
                        columnsWidths={COLUMN_WIDTHS}
                        renderCellContent={renderCellContent}
                    />
                )}
                <PaginationTable totalPages={totalPages} page={page} handlePageChange={handlePageChangeWithTracking}/>
                <ColumnsDropdownMenu
                    anchorEl={anchorEl}
                    onClose={handleCloseMenu}
                    columns={COLUMNS}
                    visibleColumns={visibleColumns}
                    onToggleColumn={toggleColumnVisibilityWithTracking}
                />
            </div>
            );
            };

            export default VCList;
