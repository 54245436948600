import React, { useEffect, useState } from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import api from "../../api";
import DataTable from "../Data/DataTable";
import {CircularProgress, Typography, Box, Avatar, Link, Grid, Breadcrumbs, Button,} from "@mui/material";
import { VC_PAGE_CONFIG } from "./VCPageConfig";
import useLoading from "../../hooks/useLoading";
import usePagination from "../../hooks/usePagination";
import PaginationTable from "../Utils/PaginationTable";
import defaultEmptyLogo from "../../assets/images/empty-user.png";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {styled} from "@mui/system";
import useShortlist from "../../hooks/useShorlist";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import defaultCover from '../../assets/images/default_cover_image.png'
import vcDefaultLogo from '../../assets/images/VC_default _logo.jpg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useTrackEvent from '../../hooks/useTrackEvent';



const StyledSubtitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    textAlign: 'left'
    }))


const VCPage = () => {
    const location = useLocation(); // Retrieve state passed from VC List
    const navigate = useNavigate();
    const { vcId } = useParams();
    const [vcDetails, setVcDetails] = useState(null);
    const { showSpinner, startLoading, stopLoading } = useLoading(false, 100, false);
    const { page, pageSize, handlePageChange } = usePagination({
        initialPage: 1,
        initialPageSize: 10, // Default rows per page
    });
    const [contacts, setContacts] = useState([]);
    const [isFavorite, setIsFavorite] = useState(false);
    const {toggleShortlist} = useShortlist();
    const handleBackToIndex = () => {
        // Navigate back to the VC List with the saved state
        navigate("/", { state: location.state });
    };

    const fetchVcDetails = async () => {
        startLoading();
        try {
            const response = await api.get(`/data/vc/${vcId}`);
            setVcDetails(response.data);
            setContacts(response.data.contacts || []); // Initialize contactsr
            setIsFavorite(response.data.in_shortlist)
        } catch (error) {
            console.error("Error fetching VC details:", error);
        } finally {
            stopLoading();
        }
    };
    const { trackAction, trackTableInteraction } = useTrackEvent({}, {
        page: "VC Profile", // Current page
        table: "VC Contacts", // Default property for table-related actions
    });

    useEffect(() => {
        fetchVcDetails();
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [vcId]);

    useEffect(() => {
        trackAction('page_view',
            { "page": "VC Profile", 'VC Id': vcId });
    }, []);

    if (showSpinner || !vcDetails) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress size={48} />
            </Box>
        );
    }

    const paginatedContacts = contacts.slice((page - 1) * pageSize, page * pageSize);
    const totalPages = Math.ceil(contacts.length / pageSize);

    // Extract country code from location
    const getCountryCode = (location) => {
        if (!location) return "";
        const parts = location.split(",");
        return parts[parts.length - 1]?.trim(); // Get the last part (country code)
    };


    const handleShortlist = async () => {
        const action = isFavorite ? 'remove' : 'add';
        trackAction('vc_shortlist_add_remove', {"action": action, "VC Id": vcId, "table":"From VC Profile Page"})
        await toggleShortlist(vcId, action)
        setIsFavorite(!isFavorite);
    };

    const handlePageChangeWithTracking = (newPage) => {
        handlePageChange(newPage);
        trackTableInteraction('table_pagination_interaction', { "table_page" : newPage});
    };

    // Convert country code to flag emoji
    const getFlagEmoji = (countryCode) => {
        if (!countryCode) return "";
        // Convert country code to Unicode regional indicator symbols
        return countryCode
            .toUpperCase()
            .replace(/./g, (char) =>
                String.fromCodePoint(127397 + char.charCodeAt())
            );
    };

    const renderCellContent = (row, column) => {
        if (column.isImage) {
            const logoUrl = row.image ? row.image : defaultEmptyLogo;
            return (
                <div style={{ display: "flex", alignItems: "center" }}
                >
                    <img
                        src={logoUrl}
                        alt={`${row.name} logo`}
                        onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop in case the fallback image also fails
                            e.target.src = defaultEmptyLogo;
                        }}
                        style={{ width: 30, height: 30, marginRight: 8, borderRadius: "50%" }}
                    />
                    <Typography variant ='T2'>{row.name} </Typography>
                </div>
            );
        }
        if (column.isLink)
            return (
                <div>
                    <a href={row.linkedin} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}
                        onClick={() =>
                        trackAction('external_link_clicked', {
                            page: 'VC Profile',
                            VCId: vcId,
                            linkType: "linkdin_user_profile_link",
                            linkUrl: row.linkedin,
                            contact_name: row.name
                        })}>
                        <OpenInNewIcon color="primary" />
                    </a>
                </div>
            )

        return <Typography variant='T3'> {row[column.id]}</Typography>;
    };

    const getDomainFromUrl = (url) => {
        try {
            const hostname = new URL(url).hostname; // Extract the hostname
            return hostname.startsWith('www.') ? hostname.slice(4) : hostname;
        } catch (error) {
            console.error("Invalid URL:", error);
            return null; // Return null if the URL is invalid
        }
    };

    const renderSectionContent = (field, isLink, isImageList, isLocation) => {
        const value = vcDetails[field];
        if (isLink && value) {
            const url = value.indexOf(':') > -1 ? value :  `//${value}`
            const showValue = field ==='social_links' ? vcDetails.name :  getDomainFromUrl(value) ? getDomainFromUrl(url) : vcDetails.name;
            return (
                <Link href={url} target="_blank" rel="noopener noreferrer external" color="primary"
                      onClick={() =>
                        trackAction('external_link_clicked', {
                            page: 'VC Profile',
                            VCId: vcId,
                            linkType: field,
                            linkUrl: url
                        })
                      }
                      sx={{
                          color : '#6941C6',
                          fontWeight : 'bold',
                          textDecoration: "none", // Removes underline
                          "&:hover": {
                              textDecoration: "underline", // Optional hover effect
                          },
                          "&:focus, &:active, &:visited": {
                              color: "#6941C6", // Prevents color change on click
                          },
                      }}>
                    {showValue} <OpenInNewIcon fontSize="small" />
                </Link>
            );
        }
        if (isImageList && Array.isArray(value)) {
            return (
                <Box display="flex" flexWrap="wrap" gap={2}>
                    {value.map((item) => (
                        <Avatar key={item.id} src={item.logo} alt={item.name} sx={{ width: 50, height: 50 }} />
                    ))}
                </Box>
            );
        }
        if (isLocation) {
            return (
                <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2" color="textSecondary">
                        {getFlagEmoji(getCountryCode(value))}
                    </Typography>
                <Typography variant="body1">
                    {value}
                </Typography>
            </Box>)
        }
        return <Typography variant="body2" color="textSecondary">{value}</Typography>;
    };

    return (
    <Box sx={{ mx: "auto", p: 4, pt:0 }}>
        <Box sx={{ position: "relative", mb: 4 }}>
            {/* Cover Image */}
                <img
                    src={vcDetails.cover_logo}
                    alt={`${vcDetails.name} cover`}
                    onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop in case the fallback image also fails
                        e.target.src = defaultCover;
                    }}
                    style={{
                        width: "100%",
                        height: "240px", // Adjust height if necessary
                        borderRadius: "8px",
                    }}
                />
            {/* Logo */}
            <Box
                sx={{
                    position: "absolute",
                    bottom: "-40px", // Moves the logo halfway over the cover image
                    left: 24, // Adjust for spacing from the left
                    zIndex: 1,
                }}
            >
                <Avatar
                    src={vcDetails.logo? vcDetails.logo : vcDefaultLogo}
                    alt={vcDetails.name}
                    sx={{
                        width: 160,
                        height: 160,
                        border: "4px solid white", // Adds a white border around the logo
                    }}
                />
            </Box>
            {/* Breadcrumbs and Actions */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ pt: 2, ml:26 }}>
                <Button
                    onClick={handleBackToIndex}
                    variant="outlined"
                    startIcon={<ArrowBackIosIcon fontSize='small' fontFamily='inter' />}
                    sx={{
                        backgroundColor: "#FCE7F6", // Light pink background
                        color: "#475467",
                        borderRadius: "8px",
                        fontFamily : 'inter',
                        border : "none",
                        fontSize: "14px",
                        fontWeight: 500,
                        textTransform: "none", // Avoid uppercase text
                        padding: "6px 16px", // Adjust padding
                        "&:hover": {
                            backgroundColor: "#FAE0F3",
                            color: "#475467",// Keep the same hover background color
                        },
                        "&:visited": {
                            backgroundColor: "#FCE7F6",
                            color: "#475467",// Keep the same hover background color
                        },
                        "& .MuiButton-startIcon": {
                            marginRight: "4px", // Adjust spacing between icon and text
                        },
                    }}
                >
                    Back to VC Index
                </Button>
                <Box display="flex" alignItems="center" gap={2}>
                    <Button
                        variant="contained"
                        startIcon={isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                        onClick={handleShortlist}
                        style={{backgroundColor: '#7F56D9', color: "white"}}
                    >
                        {isFavorite ? "Remove from your list" : "Add to your list"}
                    </Button>
                </Box>
            </Box>
        </Box>
        <Box sx={{ mt: 8 }}>
            <Grid container  sx={{ mb: 4, mt: 3 }}>
                <Grid item xs={12} md={3} sx={{mb: 4, width: "1000px", flexShrink: 0 }}>
                    {/* Left Column: Location, Website, LinkedIn */}
                    {VC_PAGE_CONFIG.sections
                        .filter((section) => section.position === "left")
                        .map((section, index) => (
                            <Box key={index} mb={2}>
                                <StyledSubtitle  color="textSecondary">
                                    {section.title}
                                </StyledSubtitle>
                                <div>
                                    {renderSectionContent(section.field, section.isLink, section.isImageList, section.isLocation)}
                                </div>
                            </Box>
                        ))}
                </Grid>
                <Grid item xs={12} md={9} sx={{flexGrow: 1}}>
                    {/* Right Column: About */}
                    {VC_PAGE_CONFIG.sections
                        .filter((section) => section.position === "right")
                        .map((section, index) => (
                            <Box key={index} mb={2}>
                                <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                                    {section.title}
                                </Typography>
                                {renderSectionContent(section.field, section.isLink, section.isImageList)}
                            </Box>
                        ))}
                </Grid>
            </Grid>
        </Box>


            {/* Contact Table */}
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Contacts
            </Typography>
            <DataTable
                columns={VC_PAGE_CONFIG.contactTable.columns}
                data={paginatedContacts}
                visibleColumns={VC_PAGE_CONFIG.contactTable.columns.reduce(
                    (acc, col) => ({ ...acc, [col.id]: true }),
                    {}
                )}
                renderCellContent={renderCellContent}
                columnsWidths={VC_PAGE_CONFIG.contactTable.columns.reduce((acc, col) => ({
                    ...acc, [col.id]: { minWidth: col.minWidth, width: col.width, maxWidth: col.maxWidth }
                }), {})}
            />
            <PaginationTable totalPages={totalPages} page={page} handlePageChange={handlePageChangeWithTracking} />
        </Box>
    );
};

export default VCPage;
